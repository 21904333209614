import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCard, PostCardSanity, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location, pageContext }) => {
    const sanityPosts = data.allSanityPost.edges

    return (
        <>
            <MetaData location={location} language={pageContext.lang}/>
            <Layout isHome={true} language={pageContext.lang}>
                <div className="container">
                    <section className="post-feed">
                        {sanityPosts.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PostCardSanity key={node.id} post={node} />
                        ))}
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!, $lang: String!) {
    allSanityPost(
        filter: {lang: {eq: $lang}},
        sort: { order: DESC, fields: [publishedAt] },
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...SanityPostFields
        }
      }
    }
  }
`
